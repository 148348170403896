import {
  Account,
  MarginResultContractor,
  DummyMember,
  GroupedMembers,
  MarginContractor,
  Project,
  MarginProject,
  MarginResultProject,
  AccountDetail,
  ProjectDetail,
  RateCard,
  SortOrder,
  DummmyProjectDetail,
} from '../../types';
import { PeriodFilteringDto } from '../accounts';
import { accountStoreKey } from './account.const';

export enum SortAccountMembersBy {
  Name = 'Name',
  PayRate = 'Pay rate',
  BillRate = 'Bill rate',
  Seniority = 'Seniority',
  Margin = 'Margin',
}

export enum GroupAccountMembersBy {
  Team = 'Team',
  Seniority = 'Seniority',
  Stream = 'Stream',
  Location = 'Location',
}

export interface AccountState {
  account: null | AccountDetail;
  project: null | ProjectDetail;
  selectedProjectId: null | string;
  accountMembers: GroupedMembers[];
  accountDummyMembers?: DummyMember[];
  accountDummyProjects?: DummmyProjectDetail[];
  sortBy: SortAccountMembersBy;
  sortOrder: SortOrder;
  groupBy: GroupAccountMembersBy;
  loading: boolean;
  error?: null | Error;
  editableMode: boolean;
  resetAllMode: boolean;

  dateFilterValue: string | null;
  dateFilterValueLocal: string | null;
  hideLoading: boolean;
  accountListFetchErrorMessage?: string | null;
}

export interface AppStateWithAccountData {
  [accountStoreKey]: AccountState;
}

export interface GetAccountData {
  accountId: string;
  projectId?: string | null;
  fetchAllMembers?: boolean | false;
  periodFiltering?: PeriodFilteringDto;
}

export interface CalculateMarginAccountData {
  projects: MarginProject[];
  periodFiltering?: PeriodFilteringDto;
  id: string;
}
export interface CalculateMarginProjectData {
  contractors: MarginContractor[];
  periodFiltering?: PeriodFilteringDto;
  id: string;
}

export interface CalculateMarginAccountResult
  extends Pick<Account, 'id' | 'grossMargin' | 'netMargin' | 'revenue'> {
  projects: MarginResultProject[];
}

export interface CalculateMarginProjectResult
  extends Pick<Project, 'id' | 'grossMargin' | 'netMargin' | 'revenue'> {
  contractors: MarginResultContractor[];
}

export interface MemberDummyMarginRatesData {
  memberId: string;
  compensationRate: number;
  billRate: number;
  isHidden: boolean;
  allocation: number;
  projectId: string;
}

export interface MemberField {
  id: string;
  fieldName: string;
  value: string | number | undefined;
  projectId: string;
}

export interface CompensationRange {
  stream: string;
  seniority: string;
  legalLocation: string;
  max: number;
  min: number;
  approvedMax: number;
  employmentType: string;
}

export interface CompensationRangesResponseDto {
  compensationRange?: CompensationRange;
  billRate?: RateCard;
}

export interface AccountSortPayloadType {
  sortBy: SortAccountMembersBy;
  sortOrder?: SortOrder;
}
