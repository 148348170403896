import {
  Box,
  IconButton,
  ListItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { COLORS } from '../../../../utils/colors';
import DataRow from './DataRow';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { priceFormat } from '../../../../components/tableCellRenders';

interface Props {
  subDepName: string;
  subDepReports: any[];
  totalBonus: number;
  totalComp: number;
  visible: boolean;
}
const SubDepartmentRow: React.FC<Props> = ({
  subDepName,
  subDepReports,
  totalBonus,
  totalComp,
  visible,
}) => {
  const [open, setOpen] = useState(false);

  const handleSetOpenCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpen(!open);
    },
    [setOpen, open],
  );

  return !visible ? (
    <></>
  ) : (
    <>
      <TableRow
        key={`${subDepName}`}
        hover={true}
        className="no-height"
        sx={{
          backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
          whiteSpace: 'nowrap',
        }}
      >
        <TableCell sx={{ pl: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1.75,
            }}
          >
            <ListItem sx={{ paddingY: 0, paddingX: 0 }}>
              <IconButton
                aria-label="Expand/collapse icon"
                size="small"
                onClick={handleSetOpenCard}
              >
                {open ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
              </IconButton>
              <Typography
                whiteSpace="nowrap"
                variant={open ? 'h5' : 'h6'}
                fontWeight={'bold'}
              >
                {subDepName}
              </Typography>
            </ListItem>
          </Box>
        </TableCell>
        <TableCell colSpan={6}></TableCell>
        <TableCell>{priceFormat({ value: totalBonus })}</TableCell>
        <TableCell>{priceFormat({ value: totalComp })}</TableCell>
        <TableCell colSpan={9}></TableCell>
      </TableRow>
      {subDepReports.map((rep, index) => (
        <DataRow key={rep.fullName} report={rep} index={index} visible={open} />
      ))}
    </>
  );
};

export default SubDepartmentRow;
