import {
  Box,
  IconButton,
  ListItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { COLORS } from '../../../../utils/colors';
import SubDepartmentRow from './SubDepartmentRow';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import DataRow from './DataRow';
import { priceFormat } from '../../../../components/tableCellRenders';

interface Props {
  depName: string;
  depReports: any;
  totalBonus: number;
  totalComp: number;
}
const DepartmentRow: React.FC<Props> = ({
  depName,
  depReports,
  totalBonus,
  totalComp,
}) => {
  const [open, setOpen] = useState(false);

  const handleSetOpenCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpen(!open);
    },
    [setOpen, open],
  );

  const subDeptBonusCompMap: any = {};

  Object.keys(depReports).forEach((subDep) => {
    subDeptBonusCompMap[subDep] = {
      totalBonus: depReports[subDep].reduce(
        (total: number, rep: any) =>
          rep.bonusShare && !rep.isGroupedDataDetail
            ? total + rep.bonusShare
            : total + 0,
        0,
      ),
      totalComp: depReports[subDep].reduce(
        (total: number, rep: any) =>
          rep.baseCompensation && !rep.isGroupedDataDetail
            ? total + rep.baseCompensation
            : total + 0,
        0,
      ),
    };
  });

  return (
    <>
      <TableRow
        key={`${depName}`}
        hover={true}
        className="no-height"
        sx={{
          backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
          whiteSpace: 'nowrap',
        }}
      >
        <TableCell sx={{ pl: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 0,
            }}
          >
            <ListItem sx={{ paddingY: 0, paddingX: 0 }}>
              <IconButton
                aria-label="Expand/collapse icon"
                size="small"
                onClick={handleSetOpenCard}
              >
                {open ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
              </IconButton>
              <Typography
                whiteSpace="nowrap"
                variant={open ? 'h5' : 'h6'}
                fontWeight={'bold'}
              >
                {depName}
              </Typography>
            </ListItem>
          </Box>
        </TableCell>
        <TableCell colSpan={6}></TableCell>
        <TableCell>{priceFormat({ value: totalBonus })}</TableCell>
        <TableCell>{priceFormat({ value: totalComp })}</TableCell>
        <TableCell colSpan={9}></TableCell>
      </TableRow>
      {Object.keys(depReports).includes(depName) ? (
        depReports[depName].map((rep: any, index: number) => (
          <DataRow
            key={rep.fullName}
            report={rep}
            index={index}
            visible={open}
          />
        ))
      ) : (
        <></>
      )}

      {Object.keys(depReports).map((subDep) =>
        subDep === depName ? (
          <></>
        ) : (
          <SubDepartmentRow
            key={subDep}
            subDepName={subDep}
            subDepReports={depReports[subDep]}
            {...subDeptBonusCompMap[subDep]}
            visible={open}
          />
        ),
      )}
    </>
  );
};

export default DepartmentRow;
