import { KpiDefinitionItem } from '../../types';

export interface AddKpiAssignmentBodyType {
  userId: string;
  kpiDefinitionId: string;
  bonusShare: number;
  bestCase?: number | null;
  worstCase?: number | null;
  startPeriod: number;
  endPeriod?: number | null;
  kpiOwnerId?: string | null;
}

export interface MassEditDto {
  worstCase?: number | null;
  bestCase?: number | null;
  kpiDefinitionId: string;
}

export enum BonusShareUpsertType {
  PERIOD = 'PERIOD',
  EFFECTIVE_FROM = 'EFFECTIVE_FROM',
}

export interface BonusShareEffectiveFromDto {
  type: BonusShareUpsertType.EFFECTIVE_FROM;
  value: number;
  effectiveFrom?: number;
}

export interface BonusSharePeriod {
  type: BonusShareUpsertType.PERIOD;
  period: number;
  bonusShare: number;
}

export interface UpdateKpiAssignmentBodyType
  extends Omit<AddKpiAssignmentBodyType, 'bonusShare'> {
  id: string;
  bonusShare?: BonusShareEffectiveFromDto | BonusSharePeriod[];
}

export interface DeleteKpiAssignmentBodyType {
  userId: string;
  userKpiAssignmentId: string;
}

export interface UserKpiAssignment
  extends Omit<UpdateKpiAssignmentBodyType, 'bonusShare'> {
  bonusShare: number;
  kpiOwner: any;
  user: any;
  kpiValues: any[];
  bonusPlanEntries: any[];
  kpiDefinition: KpiDefinitionItem;
}

export interface GetUpdatedBonus {
  userId: string;
  kpiDefinitionId: string | null | undefined;
  bonusShare: any;
  currentPeriod: number | undefined;
  effectiveDate: number | undefined;
  endPeriod?: number | null | undefined;
}

export interface UpdatedBonusListItem {
  newBonusShare: number;
  oldBonusShare: number;
  period: number;
}
