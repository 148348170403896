import { UserForSearch, User } from '../../types';
import { useMemo } from 'react';
import { separators } from '../../constants';

export function useMapUsersForSearch(options: User[]): UserForSearch[] {
  return useMemo((): UserForSearch[] => {
    return (options || []).map((option): UserForSearch => {
      const { fullName } = option;

      const names = (fullName || '').split(separators.space);

      return {
        ...option,
        names,
      };
    });
  }, [options]);
}
