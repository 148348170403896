import { MarginHighlightType } from '../constants/marginTracker';
import {
  GeneralAssessment,
  ScopeTimelineAssessment,
} from '../features/globalTracker/types';
import { UserWithPermissions } from './user';

export interface MarginRange {
  min: number;
  max: number;
}

export interface MarginHighlight {
  id: string;
  min: number;
  max: number;
  type: MarginHighlightType;
}

export interface PortfolioMargins {
  grossMargin: number;
  netMargin: number;
  revenue?: number;
}

export interface BaseAccount {
  id: string;
  name: string;
  revenue?: number | null;
  grossMargin?: number | null;
  portfolioMargins?: PortfolioMargins | null;
  netMargin?: number | null;
  sowLink?: string | null;
  grossMarginTargets?: MarginRange;
  netMarginTargets?: MarginRange;
  status?: AccountStatus;
  marginHighlights?: MarginHighlight[];
  alert?: AlertType;
  initialRevenue?: number | null;
}

export interface RateCard {
  seniority: string;
  country: string;
  stream: string;
  billRate: number;
}

export interface CompensationRange {
  legalLocation: string;
  employmentType: string;
  seniority: string;
  currency: string;
  min: number;
  max: number;
  approvedMax: number;
}

export interface Member {
  id: string;
  email: string;
  fullName: string;
  seniority: Seniority;
  stream: Stream;
  legalLocation: LegalLocation;
  compensationRate?: number | null;
  companyRateCard?: RateCard;
  allocation?: number | null;
  compensationRange?: CompensationRange;
  grossMargin?: number;
  netMargin?: number;
  resource?: {
    billRate: number;
  };
  isHidden?: boolean | false;
  dummy?: DummyMargin;
  projectId?: string;
  avatarUrl?: string;
  compensationType?: string | null;
  actualPayRate?: number | null;
}

export interface GroupedMembers {
  projectId?: string;
  groupName: string;
  members: Member[];
  margin?: number | null;
}

export interface DummyMember {
  id: string;
  seniority?: Seniority;
  stream?: Stream;
  country?: LegalLocation;
  compensationRate?: number;
  billRate?: number;
  grossMargin?: number;
  netMargin?: number;
  rateCard?: number;
  allocation: number;
  isHidden: boolean | false;
  payRange: [number, number];
  employmentType?: EmploymentType;
  projectId?: string;
}

export interface Project extends BaseAccount {
  members: Member[];
}

export interface Account extends BaseAccount {
  clientPartner?: BaseManager;
  portfolioDeliveryManager?: BaseManager;
  deliveryManager?: BaseManager;
}

export interface DummyMargin {
  netMargin?: number;
  grossMargin?: number;
  compensationRate?: number | null;
  billRate: number;
  allocation: number;
}

export type MarginContractor = Pick<
  Member,
  'id' | 'compensationRate' | 'resource' | 'allocation' | 'projectId'
> &
  Partial<Pick<Member, 'legalLocation'>>;

export type MarginProject = Pick<Project, 'id'> & {
  contractors: MarginContractor[];
};

export type MarginResultContractor = Pick<
  Member,
  'id' | 'grossMargin' | 'netMargin' | 'projectId'
>;

export type MarginResultProject = Pick<
  Project,
  'id' | 'grossMargin' | 'netMargin'
> & {
  contractors: MarginResultContractor[];
};

//NEW INTERFACES
export interface BaseManager {
  id: string;
  email?: string;
  fullName: string;
  avatarUrl: string;
  zohoId: string;
  legalLocation?: string;
  city?: string;
  designation?: string;
  stream?: string;
}

export interface BaseProject {
  id: string;
  name: string;
}

export interface ProjectOverview extends BaseProject {
  membersCount: number;
  grossMargin?: number | null;
  netMargin?: number | null;
  revenue?: number | null;
  expectedMembersCount: number;
  startedAt: string | null;
  endedAt: string | null;
  membersWithSowToExpireWithinMonth: number;
  sowStatus: SowStatus | null;
  latestScopeAssessment?: ScopeTimelineAssessment;
  latestTimelineAssessment?: ScopeTimelineAssessment;
  latestGeneralAssessment?: GeneralAssessment;
  initialRevenue?: number | null;
}

export interface ProjectReport extends BaseProject {
  membersCount: number;
  members?: Member[];
}

export type BaseProjectDetail = Omit<
  ProjectOverview,
  | 'expectedMembersCount'
  | 'startedAt'
  | 'endedAt'
  | 'membersWithSowToExpireWithinMonth'
  | 'sowStatus'
> & {
  grossMarginTargets?: MarginRange;
  netMarginTargets?: MarginRange;
  marginHighlights?: MarginHighlight[];
  clientPartner?: BaseManager;
  portfolioDeliveryManager?: BaseManager;
  deliveryManager?: BaseManager;
};

export interface ProjectDetail extends BaseProjectDetail {
  members?: Member[] | [];
}

export interface DummmyProjectDetail extends BaseProjectDetail {
  members?: DummyMember[] | [];
}

export interface FlagsCount {
  red: number;
  yellow: number;
}

export interface CustomLinks {
  accountId?: string;
  id?: string;
  title: string;
  url: string;
}

export interface AccountOverview extends BaseAccount {
  clientPartner?: BaseManager;
  portfolioDeliveryManager?: BaseManager;
  deliveryManager?: BaseManager;
  projects: ProjectOverview[];
  zohoId?: string;
  csat?: CsatValues;
  sharedDriveLink: string | null;
  status: AccountStatus;
  flagsCount: FlagsCount;
  openPositions?: number;
  customLinks: CustomLinks[] | [];
  compensationToolLink?: string;
}

export interface AccountOverviewWithAssessments extends AccountOverview {
  latestTimelineAssessmentScore?: number;
  latestScopeAssessmentScore?: number;
  latestGeneralAssessmentScore?: number;
}

export type AccountDetail = Omit<AccountOverview, 'projects'> & {
  projects: ProjectDetail[];
  grossMarginTargets?: MarginRange;
  netMarginTargets?: MarginRange;
  marginHighlights?: MarginHighlight[];
};

export interface CsatValues {
  all?: number;
  last6Months?: number;
  last3Months?: number;
  latest?: number;
}

export enum Seniority {
  JUNIOR = 'Junior',
  JUNIOR1 = 'Junior 1',
  JUNIOR2 = 'Junior 2',
  MIDDLE = 'Middle',
  MIDDLE1 = 'Middle 1',
  MIDDLE2 = 'Middle 2',
  SENIOR = 'Senior',
  SENIOR1 = 'Senior 1',
  SENIOR2 = 'Senior 2',
  LEAD = 'Lead',
  DM = 'DM',
}

export enum Stream {
  AUTOMATION_QA = 'Automation QA',
  DESIGNER = 'Designer',
  DEVELOPER = 'Developer',
  DEVOPS = 'DevOps',
  MANUAL_QA = 'Manual QA',
  PM = 'PM',
}

export enum LegalLocation {
  INDIA = 'India',
  MEXICO = 'Mexico',
  UKRAINE = 'Ukraine',
  UNITED_STATES = 'United States',
  GUATEMALA = 'Guatemala',
  COLOMBIA = 'Colombia',
  BRAZIL = 'Brazil',
  ARGENTINA = 'Argentina',
  PORTUGAL = 'Portugal',
  POLAND = 'Poland',
  REMOTE = 'Remote',
}

export enum EmploymentType {
  EMPLOYEE = 'Employee',
  CONTRACTOR = 'Contractor',
}

export interface LegalLocationType {
  id?: string;
  country?: LegalLocation;
  employmentType?: EmploymentType;
}

export enum SowStatus {
  // if startDate <= current date <= endDate
  Active = 'Active',
  // if current date < startDate
  Potential = 'Potential',
  // if endDate < current date
  Expired = 'Expired',
}

export enum AccountStatus {
  Active = 'Active',
  Closed = 'Closed',
}

export enum AlertCategory {
  CLIENT_FINANCIAL_ISSUES = 'CLIENT_FINANCIAL_ISSUES',
  IMPORTANT_CLIENT_STAKEHOLDER_CHANGES = 'IMPORTANT_CLIENT_STAKEHOLDER_CHANGES',
  AE_TEAM_PERFORMANCE_ISSUES = 'AE_TEAM_PERFORMANCE_ISSUES',
  PROJECT_DELIVERY_ISSUES = 'PROJECT_DELIVERY_ISSUES',
  AE_TEAM_ATTRITION = 'AE_TEAM_ATTRITION',
  CHALLENGES_IN_TEAM_BUILD_UP_SLOW_RECRUITING = 'CHALLENGES_IN_TEAM_BUILD_UP_SLOW_RECRUITING',
  OTHER = 'OTHER',
}

export const CategoryLabels = {
  [AlertCategory.CLIENT_FINANCIAL_ISSUES]: 'Client financial issues',
  [AlertCategory.IMPORTANT_CLIENT_STAKEHOLDER_CHANGES]:
    'Important client stakeholder changes',
  [AlertCategory.AE_TEAM_PERFORMANCE_ISSUES]: 'AE team performance issues',
  [AlertCategory.PROJECT_DELIVERY_ISSUES]: 'Project delivery issues',
  [AlertCategory.AE_TEAM_ATTRITION]: 'AE team attrition',
  [AlertCategory.CHALLENGES_IN_TEAM_BUILD_UP_SLOW_RECRUITING]:
    'Challenges in the team build-up/slow recruiting',
  [AlertCategory.OTHER]: 'Other',
};

export enum AlertResolvedStatus {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export const ResolvedStatusLabel = {
  [AlertResolvedStatus.POSITIVE]: 'Positive',
  [AlertResolvedStatus.NEGATIVE]: 'Negative',
};

export interface AlertType {
  id: string;

  name: string;
  categories: AlertCategory[];

  etaForResolve: string;
  problemStatement: string;
  whatWasDone: string;
  actionPlan: string;
  risksAndBlockers: string;

  ownerId: string;
  accountId: string;
  account: { id: string; name: string };

  resolvedStatus: AlertResolvedStatus | null;
  resolvedSummary: string | null;
  resolvedAt: string | null;

  owner: UserWithPermissions | null;
  authorId: string;
  alertEmailRecipientsEmails: string[];

  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface UpsertAlertType
  extends Pick<
    AlertType,
    | 'name'
    | 'categories'
    | 'problemStatement'
    | 'whatWasDone'
    | 'actionPlan'
    | 'risksAndBlockers'
    | 'accountId'
    | 'resolvedStatus'
    | 'resolvedSummary'
    | 'resolvedAt'
    | 'owner'
    | 'authorId'
    | 'alertEmailRecipientsEmails'
  > {
  ownerId: string | null;
  etaForResolve: string;
}

export interface ResolveAlertType {
  resolvedStatus: AlertResolvedStatus;
  resolvedSummary: string;
  id: string;
}
