import { ContractorState } from './contractor.slice';
import { BonusHistory, ContractorType } from '../../types/contractor';
import { contractorStoreKey } from './contractor.const';
import { EditableKpiType } from '../../types/kpiAssign';

interface State {
  [contractorStoreKey]: ContractorState;
}

const selectContractorState = (state: State) => {
  return state[contractorStoreKey];
};

const getContractorData = (state: State): ContractorType => {
  return selectContractorState(state).contractorData;
};

const getContractorApiStatus = (state: State): string => {
  return selectContractorState(state).apiStatus;
};

const getContractorKpiLoading = (state: State): string[] => {
  return selectContractorState(state).loadingAllResultsFor;
};

const getBonusHistory = (state: State): BonusHistory[] => {
  return selectContractorState(state).bonusHistory;
};

const isUpdatingHistoricalData = (state: State): boolean => {
  return selectContractorState(state).isUpdatingHistoricalData;
};

const getAssignmentToUpdate = (state: State): EditableKpiType | null => {
  return selectContractorState(state).kpiToUpdate;
};

const getAllAssignmentsWithHistoricalData = (state: State) => {
  return selectContractorState(state).allAssignments;
};

const getUpdatedBonusList = (state: State) => {
  return selectContractorState(state).updatedBonuslist;
};

const getMassEditUsers = (state: State) => {
  return selectContractorState(state).massEditUsers;
};

const getMassEditTotalUsersCount = (state: State) => {
  return selectContractorState(state).massEditTotalUsersCount;
};

export const contractorSelectors = {
  getContractorData,
  getContractorApiStatus,
  getContractorKpiLoading,
  getBonusHistory,
  isUpdatingHistoricalData,
  getAssignmentToUpdate,
  getAllAssignmentsWithHistoricalData,
  getUpdatedBonusList,
  getMassEditUsers,
  getMassEditTotalUsersCount,
};
