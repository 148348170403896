import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { contractorsStoreKey } from './contractors.const';
import { DepartmentType } from '../../types';
import { DepartmentView } from '../../types/contractor';

export const fetchDepartments = createAsyncThunk(
  `${contractorsStoreKey}/fetchDepartments`,
  async (view: DepartmentView, thunkAPI) => {
    try {
      console.log('fetching departments');
      const response = await axios.get<DepartmentType[]>(
        apiEndpoints.departments(view),
      );

      // todo: quick fix to remove alex.kalinovsky@agileengine.com from list
      const result = (response.data || []).map(
        (department: DepartmentType) => ({
          ...department,
          members: department.members.filter(
            (member) => member.email !== 'alex.kalinovsky@agileengine.com',
          ),
        }),
      );

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
