import React, { useMemo } from 'react';
import { Avatar, Box, Stack, styled, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useAppSelector } from '../../../redux/hooks';
import { userDataSelectors } from '../../../redux/userData';
import {
  MemberInfoCell,
  priceFormat,
} from '../../../components/tableCellRenders';
import {
  getAccountDummyProjects,
  getSelectedProject,
  getVisibleAccountDummyMembers,
  selectHiddenAccountMembers,
} from '../../../redux/account';
import { AccountDetail, DummyMember, ProjectDetail } from '../../../types';
import { avatarMediumSize } from '../../../theme';
import { Info } from '../../../features/budgets/BudgetsHeader/Info/Info';
import { CustomNumber } from '../../../features/budgets/BudgetsHeader/Number/Number';
import { GrossMarginSummary } from '../../../features/budgets/BudgetsHeader/GrossMarginSummary/GrossMarginSummary';

export const StyledBudgetsSummary = styled(Box)<{ isClientPartner?: boolean }>`
  display: inline-flex;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: ${(props) => props.theme.spacing(12)};
  padding: ${(props) => props.theme.spacing(1.5, 2.5)};
  gap: ${(props) => (props.isClientPartner ? null : props.theme.spacing(5))};
  border-radius: ${(props) => props.theme.spacing(1, 1, 0, 0)};
`;

interface Props {
  account: AccountDetail;
  projects: ProjectDetail[] | null;
  handleOnOpenRaiseBudgetModal?: () => void;
  isProjectView: boolean;
}

export const MyPortfolioHeaderForDetails = ({
  account,
  projects,
  isProjectView,
}: Props) => {
  const visibleAccountDummyMembers: DummyMember[] | undefined = useAppSelector(
    getVisibleAccountDummyMembers,
  );

  const hiddenAccountMembers = useAppSelector(selectHiddenAccountMembers);
  const dummyProjects = useAppSelector(getAccountDummyProjects);
  const project = useAppSelector(getSelectedProject);
  const budget = project ? project : account;

  const user = useAppSelector(userDataSelectors.getUserData);
  const hasAccounts = Boolean(user?.permissions?.hasAccounts);

  const { membersInTeams } = useMemo(() => {
    return (
      projects?.reduce(
        (
          { membersInTeams }: { membersInTeams: number },
          project: ProjectDetail,
        ) => ({
          membersInTeams: membersInTeams + project.membersCount,
        }),
        { membersInTeams: 0 },
      ) ?? { membersInTeams: 0 }
    );
  }, [projects]);

  const updatedMembers = useMemo(() => {
    return visibleAccountDummyMembers?.length
      ? membersInTeams + visibleAccountDummyMembers.length
      : membersInTeams;
  }, [visibleAccountDummyMembers, membersInTeams]);

  return (
    <StyledBudgetsSummary>
      {hasAccounts && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: '90%',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {!isProjectView && (
              <Info title="Teams">
                <Box display="flex" flexDirection="row" paddingLeft={'3px'}>
                  <CustomNumber>
                    {(projects?.length ?? 0) + (dummyProjects?.length ?? 0)}
                  </CustomNumber>
                  {!!dummyProjects?.length && (
                    <Stack
                      ml={1}
                      display="flex"
                      fontSize={11}
                      sx={{
                        justifyContent: 'inherit',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <AddRoundedIcon
                          fontSize="small"
                          sx={{ width: 12, height: 12 }}
                        />
                        {dummyProjects.length}
                      </Box>
                    </Stack>
                  )}
                </Box>
              </Info>
            )}
            <Info title="Headcount">
              <Box display="flex" flexDirection="row" paddingLeft={'3px'}>
                <CustomNumber
                  superscript={visibleAccountDummyMembers?.length ?? undefined}
                  subscript={hiddenAccountMembers?.length ?? undefined}
                >
                  {updatedMembers - hiddenAccountMembers?.length}
                </CustomNumber>
              </Box>
            </Info>

            <GrossMarginSummary
              marginHighlights={budget.marginHighlights}
              grossMargin={budget?.grossMargin ?? undefined}
            />

            <Info title="Projected revenue">
              <Box display="flex" flexDirection="row" paddingLeft={'0px'}>
                <CustomNumber>
                  {priceFormat({
                    value: budget.revenue?.toFixed(0),
                  })}
                </CustomNumber>
              </Box>
            </Info>

            <Box
              display="flex"
              flexDirection="row"
              gap={'7%'}
              sx={{
                marginRight: '0',
                justifyContent: 'start',
                minWidth: '58%',
                maxWidth: '60%',
              }}
            >
              <Info title="PDM">
                {account.portfolioDeliveryManager ? (
                  <MemberInfoCell userInfo={account.portfolioDeliveryManager} />
                ) : (
                  <NotAssignedMemberCell />
                )}
              </Info>
              <Info title="DM">
                {account.deliveryManager ? (
                  <MemberInfoCell userInfo={account.deliveryManager} />
                ) : (
                  <NotAssignedMemberCell />
                )}
              </Info>
            </Box>
          </Box>
        </>
      )}
    </StyledBudgetsSummary>
  );
};

const NotAssignedMemberCell: React.FC = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Avatar
        src={''}
        sx={{
          width: avatarMediumSize,
          height: avatarMediumSize,
          bgcolor: 'gray',
        }}
      />
      <Typography variant="h4" sx={{ marginLeft: 1, opacity: 0.7 }}>
        Not Assigned
      </Typography>
    </Stack>
  );
};
