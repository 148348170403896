import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { kpiMetricsStoreKey } from './kpiMetrics.const';

export const fetchKpiMetrics = createAsyncThunk(
  `${kpiMetricsStoreKey}/fetchKpiMetrics`,
  async ({ userId, byZoho }: { userId: string; byZoho?: boolean }) => {
    try {
      const response = await axios.get(
        byZoho
          ? apiEndpoints.kpiMetricsByZoho(userId)
          : apiEndpoints.kpiMetrics(userId),
      );
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);
