import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Tooltip,
} from '@mui/material';
import { ExpandableTableAccountProps } from '../types';
import { AccountOverviewWithAssessments, SortOrder } from '../../../types';
import AccountRow from './AccountRow/AccountRow';
import { paths } from '../../../constants/paths';
import {
  accountsSelectors,
  accountsSlice,
  getSortBy,
  getSortOrder,
  SortAccountsBy,
} from '../../../redux/accounts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CustomTableCell } from '../../../components/customTable';

export const AccountsTable = ({
  accounts,
  isCLevelUser,
}: ExpandableTableAccountProps) => {
  const navigate = useNavigate();
  const errMessage = useAppSelector(
    accountsSelectors.getAccountsListFetchErrorMessage,
  );
  const onSelectAccount = useCallback(
    (account: AccountOverviewWithAssessments) => {
      navigate(`${paths.portfolio}/${account.id}`);
    },
    [navigate],
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 0,
      }}
    >
      <Table aria-label="collapsible table" stickyHeader>
        <ExpandableTableHeaderAccount isCLevelUser={isCLevelUser} />
        {accounts.length > 0 ? (
          <TableBody>
            {accounts.map((account) => (
              <AccountRow
                key={account.id}
                onClick={() => onSelectAccount(account)}
                account={account}
                isCLevelUser={isCLevelUser}
              />
            ))}
          </TableBody>
        ) : (
          <CustomTableCell colSpan={13} pure>
            <TableBody
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                marginTop: '3%',
                marginBottom: '3%',
                fontSize: '1rem',
                color: 'grey',
              }}
            >
              {errMessage}
            </TableBody>
          </CustomTableCell>
        )}
      </Table>
    </Paper>
  );
};

// ExpandableTableHeaderAccount

const sortableColumns: Record<string, SortAccountsBy> = {
  Accounts: SortAccountsBy.AccountName,
  Margin: SortAccountsBy.Margin,
  Headcount: SortAccountsBy.Headcount,
};

export interface ExpandableTableHeaderAccountProps {
  isClientPartner?: boolean;
  isCLevelUser: boolean;
}

const ExpandableTableHeaderAccount = ({
  isCLevelUser,
}: ExpandableTableHeaderAccountProps) => {
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(getSortBy);
  const sortOrder = useAppSelector(getSortOrder);

  const handleColumnSort = useCallback(
    (columnName: string) => {
      const newSortBy = sortableColumns[columnName];

      if (newSortBy) {
        const newSortOrder =
          newSortBy === sortBy ? 1 - sortOrder : SortOrder.Ascending;
        dispatch(
          accountsSlice.actions.setSortBy({
            sortBy: newSortBy,
            sortOrder: newSortOrder,
          }),
        );
      }
    },
    [dispatch, sortOrder, sortBy],
  );

  return (
    <TableHead>
      <TableRow
        sx={{
          '& .MuiTableCell-root': {
            padding: '8px 8px',
          },
        }}
      >
        <TableCell>
          <TableSortLabel
            direction={sortOrder === SortOrder.Ascending ? 'asc' : 'desc'}
            active={sortBy === SortAccountsBy.AccountName}
            onClick={() => handleColumnSort('Accounts')}
          >
            <Typography variant="body2" sx={{ paddingLeft: '12px' }}>
              Accounts
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          <Typography whiteSpace="nowrap" variant="body2">
            <TableSortLabel
              direction={sortOrder === SortOrder.Ascending ? 'asc' : 'desc'}
              active={sortBy === SortAccountsBy.Margin}
              onClick={() => handleColumnSort('Margin')}
              sx={{ marginRight: '-12px' }}
            >
              Margin
            </TableSortLabel>
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Tooltip
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
            title={`Revenue = contractor's bill rate × allocation × avg. monthly hours (by legal location)`}
            placement="top"
          >
            <Typography sx={{ textAlign: 'end' }} variant="body2">
              Projected <br />
              revenue
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" padding={0}>
            <TableSortLabel
              direction={sortOrder === SortOrder.Ascending ? 'asc' : 'desc'}
              active={sortBy === SortAccountsBy.Headcount}
              onClick={() => handleColumnSort('Headcount')}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Headcount
              <br />
              and positions
            </TableSortLabel>
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography whiteSpace="nowrap" variant="body2">
            Flags
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">SOW</Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            paddingLeft: 0,
            paddingRight: '0px',
          }}
        >
          <Typography variant="body2">Forecasted end</Typography>
        </TableCell>
        {isCLevelUser && (
          <TableCell align="left">
            <Typography whiteSpace="nowrap" variant="body2">
              PDM
            </Typography>
          </TableCell>
        )}
        <TableCell align="left">
          <Typography whiteSpace="nowrap" variant="body2">
            DM
          </Typography>
        </TableCell>
        <TableCell>
          <Typography whiteSpace="nowrap" variant="body2">
            CSAT
          </Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
