import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../utils/colors';
import {
  priceFormat,
  priceFromatEmptyValue,
} from '../../../../components/tableCellRenders';
import { capitalizeFirstLetter } from '../../../globalTracker/utils';
import { formatYYYYMMDate } from '../../../../utils';

interface Props {
  report: any;
  index: number;
  visible: boolean;
}
const DataRow: React.FC<Props> = ({ report, index, visible }) => {
  return !visible ? (
    <></>
  ) : (
    <TableRow
      key={`${report.fullName}_${index}`}
      hover={true}
      className="no-height"
      sx={{
        backgroundColor: report?.isGroupedDataHeading
          ? COLORS.HIGHLIGHTS.NEUTRAL
          : '',
        whiteSpace: 'nowrap',
      }}
    >
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
          pl: 6,
        }}
      >
        {report.fullName || priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.role ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.seniority ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell>{report.metricName || priceFromatEmptyValue}</TableCell>
      <TableCell>
        {report.frequency
          ? capitalizeFirstLetter(report.frequency)
          : priceFromatEmptyValue}
      </TableCell>
      <TableCell>{report.worstCase ?? priceFromatEmptyValue}</TableCell>
      <TableCell>{report.bestCase ?? priceFromatEmptyValue}</TableCell>
      <TableCell>{priceFormat({ value: report.bonusShare })}</TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {priceFormat({ value: report.baseCompensation })}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {capitalizeFirstLetter(report.compensationType ?? '') ||
          priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {formatYYYYMMDate(report.period, 'MMMM')}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {formatYYYYMMDate(report.period, 'yyyy')}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.location ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.averageMonthlyHours ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.department ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.subDepartment ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell
        sx={{
          color: report.isGroupedDataDetail ? COLORS.TEXT.DISABLED : '',
        }}
      >
        {report.reportingToUser ?? priceFromatEmptyValue}
      </TableCell>
      <TableCell>{report.kpiOwner || priceFromatEmptyValue}</TableCell>
    </TableRow>
  );
};

export default DataRow;
