export const REPORT_KEYS = {
  bonus: 'Bonus',
  trueUp: 'True Up Correction',
  finalBonus: 'Final Bonus',
  bonusType: 'Bonus type',
  department: 'Department',
  subDepartment: 'Sub Department',
  fullName: 'Full name',
  metricName: 'Metric',
  location: 'Location',
  period: 'Month Year',
  city: 'City office',
  frequency: 'Frequency',
  Seniority: 'Seniority',
  role: 'Role',
  reportingTo: 'Reporting To',
  kpiMetricName: 'KPI Metric name',
  kpiOwner: 'KPI Owner',
  worstCase: 'Worst case',
  bestCase: 'Best case',
  targetBonus: 'Target Bonus',
  baseCompensation: 'Base Compensation',
  compensationType: 'Base Compensation type',
  month: 'Month',
  year: 'Year',
  averageMonthlyHours: 'Average monthly hours',
};
