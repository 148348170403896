import React, { useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Routes from './routes/Routes';
import { acceptTermsOfUse, userDataSelectors } from './redux/userData';
import { Loader } from './components';
import useNotifier from './hooks/useNotifier';
import { useObserveTitle } from './hooks/useObserveTitle';
import { Unauthorized } from './pages/unauthorized/unauthorized';
import { paths } from './constants/paths';
import { CookieWarningView } from './pages/cookieWarningView/CookieWarningView';
import { useAuth } from './hooks/useAuth';
import { appConfig } from './constants';
import { InterceptorService } from './services/interceptors-service';
import { TermsOfUseOverlay } from './components/termsOfUse/TermsOfUseOverlay';
import { TERMS_OF_USE_VERSION } from './components/termsOfUse/constants';
import { useAppDispatch } from './redux/hooks';
import { RockyApiService } from './services/rocky-api';
import { RockyComponentsProvider } from '@product-studio/rocky-components';
import { is } from './utils';
import { AuthService } from './services/auth-service';

const App = () => {
  useNotifier();
  useObserveTitle();

  const nodeRef = React.useRef(null);
  const dispatch = useAppDispatch();

  const unauthorized: boolean = useSelector(userDataSelectors.getUnauthorized);
  const rockyUser: any = useSelector(userDataSelectors.getRockyUserData);
  const isRockyBonusesPage = window.location.pathname.includes(
    paths.rockyBonuses,
  );

  useEffect(() => {
    InterceptorService.init(appConfig);
    RockyApiService.init(appConfig);
  }, []);

  // if Rocky Bonuses page - no auth needed, because it's already have setted token from rocky
  const { showInstructions, user } = useAuth();

  const acceptTou = useCallback(() => {
    dispatch(acceptTermsOfUse(TERMS_OF_USE_VERSION));
  }, [dispatch]);

  const content = useMemo(() => {
    if (showInstructions) {
      return <CookieWarningView />;
    }

    if (isRockyBonusesPage) {
      return <Routes />;
    }

    if (unauthorized) {
      return <Unauthorized />;
    }

    if (
      rockyUser !== null &&
      rockyUser.latestTermsOfUseVersionAccepted !== TERMS_OF_USE_VERSION
    ) {
      return <TermsOfUseOverlay onTermsAccept={acceptTou}></TermsOfUseOverlay>;
    }

    if (!user) {
      return (
        <CSSTransition
          nodeRef={nodeRef}
          key="loader"
          timeout={300}
          classNames="loader"
        >
          <Loader fullPage nodeRef={nodeRef} />
        </CSSTransition>
      );
    }

    return <Routes />;
  }, [
    showInstructions,
    isRockyBonusesPage,
    user,
    unauthorized,
    rockyUser,
    acceptTou,
  ]);

  return (
    <RockyComponentsProvider
      token={AuthService.getToken() ?? ''}
      env={is.development ? 'dev' : 'prod'}
    >
      <BrowserRouter>
        <TransitionGroup exit>{content}</TransitionGroup>
      </BrowserRouter>
    </RockyComponentsProvider>
  );
};

export default App;
