import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ReportType, TypeOfReport } from '../../types/reports';
import ReportsTable from './ReportsTable';
import { KpiStatus } from '../../constants';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import emptyStateLogo from '../../assets/noReports.svg';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDownloadCsv: () => void;
  handleDownloadCompensationCsv: () => void;
  reports: ReportType[];
  typeOfReport: TypeOfReport | null;
}

const PreviewModal: React.FC<Props> = ({
  reports,
  handleDownloadCsv,
  handleDownloadCompensationCsv,
  isOpen,
  onClose,
  typeOfReport,
}) => {
  const theme = useTheme();

  const noReports = reports.length === 0;
  const anyUnpprovedReports =
    reports.filter((x) => x.status !== KpiStatus.Approved).length !== 0;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={false}>
      <DialogTitle>
        Report preview
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 11,
            color: ({ palette }) => palette.action.active,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }} dividers>
        {noReports ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: `${theme.spacing(21)} ${theme.spacing(51)}`,
              h2: {
                width: theme.spacing(36),
              },
            }}
          >
            <img src={emptyStateLogo} alt={'no results'} />
            <Typography variant="h2" color="text.secondary" pt={1}>
              No results for these locations and departments
            </Typography>
          </Box>
        ) : (
          <ReportsTable reports={reports} typeOfReport={typeOfReport} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          disabled={
            (anyUnpprovedReports && typeOfReport === TypeOfReport.METRICS) ||
            noReports
          }
          onClick={
            typeOfReport === TypeOfReport.METRICS
              ? handleDownloadCsv
              : handleDownloadCompensationCsv
          }
          variant="contained"
        >
          Download .CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;
