import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { contractorStoreKey } from './contractor.const';
import {
  AddKpiAssignmentBodyType,
  UpdateKpiAssignmentBodyType,
  DeleteKpiAssignmentBodyType,
  GetUpdatedBonus,
  MassEditDto,
} from './contractor.types';

export const fetchContractor = createAsyncThunk(
  `${contractorStoreKey}/fetchContractor/`,
  async (
    {
      userId,
      dateFrom,
      dateTo,
      byZoho,
      selfMetrics,
    }: {
      userId: string;
      dateFrom?: string;
      dateTo?: string;
      byZoho?: boolean;
      selfMetrics?: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const queries = [
        dateFrom ? `from=${dateFrom}` : null,
        dateTo ? `to=${dateTo}` : null,
      ]
        .filter((v) => v)
        .join('&');

      const response = await axios.get(
        selfMetrics
          ? `${apiEndpoints.myMetrics()}?${queries}`
          : `${
              byZoho
                ? apiEndpoints.contractorsByZoho()
                : apiEndpoints.contractor()
            }/${userId}?${queries}`,
      );

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchKpiAllTimeMetrics = createAsyncThunk(
  `${contractorStoreKey}/fetchKpiAllTimeMetrics/`,
  async (
    {
      kpiId,
      userId,
      dateFrom,
      dateTo,
      byZoho,
      selfMetrics,
    }: {
      kpiId: string;
      userId: string;
      dateFrom?: string;
      dateTo?: string;
      byZoho?: boolean;
      selfMetrics?: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const queries = [
        dateFrom ? `from=${dateFrom}` : null,
        dateTo ? `to=${dateTo}` : null,
      ]
        .filter((v) => v)
        .join('&');

      const response = await axios.get(
        selfMetrics
          ? `${apiEndpoints.myMetrics()}?${queries}`
          : `${
              byZoho
                ? apiEndpoints.contractorsByZoho()
                : apiEndpoints.contractor()
            }/${userId}?${queries}`,
      );

      return { data: response.data, kpiId };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const addKpiAssignment = createAsyncThunk(
  `${contractorStoreKey}/addKpiAssignment/`,
  async (body: AddKpiAssignmentBodyType, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiEndpoints.kpiAssignments()}`,
        body,
      );
      thunkAPI.dispatch(notificationAlert('New metric assigned'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const massUpdateKpiAssignment = createAsyncThunk(
  `${contractorStoreKey}/massUpdateKpiAssignment/`,
  async (body: MassEditDto, thunkAPI) => {
    try {
      const response = await axios.put(
        `${apiEndpoints.massEditKpiAssignments()}`,
        body,
      );
      thunkAPI.dispatch(notificationAlert('Metric updated'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const updateKpiAssignment = createAsyncThunk(
  `${contractorStoreKey}/updateKpiAssignment/`,
  async (body: UpdateKpiAssignmentBodyType, thunkAPI) => {
    try {
      const response = await axios.put(
        `${apiEndpoints.kpiAssignments()}`,
        body,
      );
      thunkAPI.dispatch(notificationAlert('Metric updated'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const deleteKpiAssignment = createAsyncThunk(
  `${contractorStoreKey}/deleteKpiAssignment/`,
  async (body: DeleteKpiAssignmentBodyType, thunkAPI) => {
    try {
      await axios.delete(`${apiEndpoints.kpiAssignments()}`, { data: body });
      thunkAPI.dispatch(notificationAlert('Metric deactivated'));
      return body.userKpiAssignmentId;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const getKpiAssignment = createAsyncThunk(
  `${contractorStoreKey}/getKpiAssignment/`,
  async (assignmentId: string, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${apiEndpoints.kpiAssignments()}/${assignmentId}`,
      );
      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      console.error(err);
      throw new Error(String(err));
    }
  },
);

export const fetchMassEditUsers = createAsyncThunk(
  `${contractorStoreKey}/fetchMassEditUsers`,
  async (kpiDefinitionId: string, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.usersOfKpiDefinition(), {
        kpiDefinitionId,
      });

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      console.error(err);
      throw new Error(String(err));
    }
  },
);

export const getAllAssignmentsWithHistoricalData = createAsyncThunk(
  `${contractorStoreKey}/getAllAssignmentsWithHistoricalData`,
  async (userId: string) => {
    try {
      const response = await axios.get(
        apiEndpoints.allKpiAssignmentsForUser(userId),
      );

      return response.data;
    } catch (error) {
      throw new Error(String(error));
    }
  },
);

export const getSharedResultsForAssignment = createAsyncThunk(
  `${contractorStoreKey}/getSharedResultsForAssignment`,
  async (kpiId: string) => {
    try {
      const response = await axios.get(
        apiEndpoints.sharedResultsForAssignment(kpiId),
      );

      return response.data;
    } catch (error) {
      throw new Error(String(error));
    }
  },
);

export const getUpdatedBonuslist = createAsyncThunk(
  `${contractorStoreKey}/getUpdatedBonuslist/`,
  async (body: GetUpdatedBonus, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiEndpoints.bonusPlanUpdateSnippet()}`,
        body,
      );
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
